import { useCallback, useEffect, useRef, useState } from "react";
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Highlighter from "react-highlight-words";
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import FocusLock from 'react-focus-lock';

import "./style.css";
import Logo from '../images/upwork.png';

import Anthony from "../images/users/Anthony.jpg";
import Constantin from "../images/users/Constantin.jpg";
import Chang from "../images/users/Chang.jpg";

import Pumba from "../images/users/Pumba.jpg";
import CP from "../images/users/CP.jpg";
import Timon from "../images/users/Timon.jpg";

import { useNavigate } from 'react-router-dom';
import MyProposals from "./proposal";


import axios from 'axios';

const Home = () => {

    const avatar_arr = [Anthony, Constantin, Chang, Pumba, CP, Timon];
    const navigate = useNavigate();

    const [keyword, setKeyword] = useState("");
    const [withContent, setWithContent] = useState(true);
    const [category, setCategory] = useState([]);
    const [visible, setVisible] = useState(false);
    const inputRef = useRef();
    const [proposals, setProposals] = useState([
        { upwork_title: 'Loading...', upwork_content: 'Loading...', upwork_proposal: 'Loading...' },
    ]);

    const [open, setOpen] = useState(false);

    const [userInfo, setUserInfo] = useState({ userId: '', password: '', permission: 0 });

    const [hardList, setHardList] = useState([
        { userId: 'rothschild', password: 'rothschild123!', permission: 100 },
        { userId: 'pjihjs1104', password: 'pjihjs1104', permission: 2 },
        { userId: 'kdb1213', password: 'kdb1213', permission: 2 },
        { userId: 'torres', password: 'torres123!', permission: 2 },
    ]);

    const getData = async () => {
        // Removed the code for safety.
    };

    useEffect(() => {
        const ret = localStorage.getItem('upwork');

        let i = 0;
        for (i = 0; i < hardList.length; i++) {
            if (ret == hardList[i].password) {
                setUserInfo(hardList[i]);
                break;
            }
        }

        if (i >= hardList.length) {
            navigate("/");
        } else if (hardList[i].permission > 1) {
            getData();
        }
    }, []);

    const handleUserKeyup = useCallback(e => {
        if (e.key === "Escape") { //Escape key pressed
            e.preventDefault();
            inputRef.current.focus();
            setKeyword("");
        }
        else if (e.key === "/") { //Give input focus
            e.preventDefault();
            inputRef.current.focus();
            inputRef.current.select();
        }
        else if (e.key === "\\") { //Content Search
            e.preventDefault();
            setWithContent(prev => !prev);
        }
        else if (e.key === "F9") { //Content Search
            e.preventDefault();
            setVisible(prev => !prev);
        }
    }, []);

    useEffect(() => {
        window.addEventListener("keyup", handleUserKeyup);
        return () => {
            window.removeEventListener("keyup", handleUserKeyup);
        }
    }, [handleUserKeyup]);

    return (
        <div>
            <MyProposals
                open={open}
                setOpen={setOpen}
                userInfo={userInfo}
            />
            <div className="d-flex flex-column bg-white" >
                <header style={{
                    background: 'white',
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100px',
                    padding: '20px',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <img src={Logo} alt="Logo" style={{ height: '40px', cursor: 'pointer' }} />
                    <div className="d-flex align-items-center justify-content-center">

                        <div className="d-flex w-48 align-items-center gap-4">
                            <button style={{
                                widdth: '300px',
                                height: '60px',
                                borderRadius: '20px',
                                padding: '10px'
                            }} onClick={() => {
                                setOpen(true);
                            }}>
                                Special Proposals
                            </button>
                            <input type="checkbox" id="myCheckbox"
                                style={{ padding: '10px', width: '30px', height: '30px', cursor: 'pointer' }}
                                checked={withContent}
                                label={`Search Contents(\\)`}
                                onChange={e => setWithContent(e.target.checked)}
                            />
                            <input
                                type="input"
                                placeholder="Search keyword..."
                                style={{
                                    width: '400px',
                                    padding: '15px',
                                    fontSize: '24px',
                                    height: '50px',
                                    border: '1px solid gray !important',
                                    borderRadius: '10px',
                                }}
                                onChange={e => setKeyword(e.target.value)}
                                value={keyword}
                                ref={inputRef}
                            />
                        </div>

                    </div>
                </header>

                <div>
                    <Accordion className="preline" >
                        {proposals.length > 0 && [...proposals].sort((a, b) => a < b).filter(d => {
                            const keywords = keyword.split(" ");
                            if (withContent) {
                                return keywords.every((key) => {
                                    return d.upwork_title.toLowerCase().includes(key.toLowerCase()) || d.upwork_content.toLowerCase().includes(key.toLowerCase());
                                })
                            }
                            else {
                                return keywords.every((key) => {
                                    return d.upwork_title.toLowerCase().includes(key.toLowerCase());
                                })
                            }
                        }).filter(d => category.every((c) => !c) || category[d.type])
                            .map((datum, index) => {
                                return (
                                    <Accordion.Item eventKey={index} key={index} style={{ background: '#f8f9fa', border: '1px solid #ccc', margin: '20px' }}>

                                        <Accordion.Header className="custom-header">
                                            {datum.upwork_user in [0, 1, 2, 3, 4, 5] &&
                                                <div>
                                                    <img src={avatar_arr[datum.upwork_user]} style={{ width: '40px', height: '40px', borderRadius: '20%', border: '2px solid white' }}></img>
                                                </div>}

                                            {/* <Badge pill bg="light" style={{ color: 'black' }}/> */}

                                            <Highlighter
                                                className="ps-3"
                                                searchWords={keyword.split(" ")}
                                                autoEscape={true}
                                                textToHighlight={datum.upwork_title}
                                            />

                                            <div style={{ marginLeft: 'auto' }}>
                                                <span className="proposal-status proposal-date"> {datum._dateFormat}</span>
                                                {datum.viewed &&
                                                    <span className="proposal-status proposal-viewed"> Viewed</span>
                                                }
                                                {datum.viewed && datum.interview &&
                                                    <span className="proposal-status proposal-interview"> Interview</span>
                                                }
                                            </div>

                                        </Accordion.Header>


                                        <Accordion.Body className="text-start text-content"
                                            style={{ background: 'rgba(255,255,255,0.7)', color: 'black', padding: '10px' }}
                                        >
                                            <div style={{ padding: '10px', background: 'rgba(255,255,255,0.8)' }}>
                                                <h3 className="mb-4">
                                                    <Highlighter
                                                        className=""
                                                        searchWords={keyword.split(" ")}
                                                        autoEscape={true}
                                                        textToHighlight={datum.upwork_title}
                                                    />
                                                </h3>
                                                <Highlighter
                                                    highlightClassName="highlight"

                                                    searchWords={keyword.split(" ")}
                                                    autoEscape={true}
                                                    textToHighlight={datum.upwork_content}
                                                />
                                            </div>
                                            <div style={{ padding: '10px', background: 'rgba(255,255,255,0.8)' }}>
                                                <h3 className="mb-4">Cover letter</h3>
                                                <Highlighter
                                                    highlightClassName="highlight"
                                                    searchWords={keyword.split(" ")}
                                                    autoEscape={true}
                                                    textToHighlight={datum.upwork_proposal}
                                                />

                                            </div>
                                        </Accordion.Body>

                                    </Accordion.Item>
                                )
                            }
                            )}
                    </Accordion>
                </div >
            </div>


        </div>
    );
}

export default Home;
